
































































































































































import { Observer } from 'mobx-vue'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { profileController } from '../viewmodels/profile-viewmodel'

@Observer
@Component({
  components: {
    observer: () => import('vue-intersection-observer'),
  },
})
export default class Activity extends Vue {
  profileController = profileController
  @Ref('activity-menu') activityMenu: any

  get lastPath() {
    const fullPath = this.$route.fullPath
    const paths = fullPath.split('/')
    return paths[paths.length - 1]
  }

  gotoTargetRoute(tab: string) {
    if (tab == this.lastPath) return
    const currentParams = this.$router.currentRoute.params.userId
    const targetRoute = `/profile/${currentParams}/activity/${tab}`
    this.$router.push(targetRoute)
  }

  select = { label: 'Posts', icon: 'activity-post-ic.svg', path: 'posts' }
  focusActivityMenu(entry, unobserve) {
    if (!entry.isIntersecting) {
      this.activityMenu?.blur()
    }
  }
}
